import React from 'react'
import { Menu, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import IconLabel from "./IconLabel"
import VerticalItemMenuDivider from "./VerticalMenuItemDivider";
import MediaQuery from 'react-responsive';
import styles from './NormalMenu.module.scss';


export default class NormalMenu extends React.Component{

    constructor(props){
        super(props)
    }

    render(){

        const MenuItems = (
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={this.props.selectedKeys}
                style={{lineHeight: "62px"}}
            >
                <Menu.Item key="/">
                    <Link to="/">Domov</Link>
                </Menu.Item>
                <VerticalItemMenuDivider />
                <Menu.Item key="/predaj-servis">
                    <Link to="/predaj-servis">Predaj & Servis</Link>
                </Menu.Item>
                <VerticalItemMenuDivider />
                <Menu.Item key="/galeria">
                    <Link to="/galeria">Galéria</Link>
                </Menu.Item>
                <VerticalItemMenuDivider/>
                <Menu.Item key="/kontakt">
                    <Link to="/kontakt">Kontakt</Link>
                </Menu.Item>
            </Menu>
        )

        return(
            <React.Fragment>
                <MediaQuery query="(min-width: 1501px)">
                    <Row className={styles["large-nav-wrapper"]}>
                        <Col span={7} className={styles["large-logo-wrapper"]}>
                            <img src={this.props.logo} style={{height: "60px"}}></img>
                        </Col>
                        <Col span={10}>
                            <Row className={styles["large-menu-wrapper"]}>
                                {MenuItems}
                            </Row>
                        </Col>
                        <Col span={7} className={styles["large-contact-wrapper"]}>
                            <div>
                                <IconLabel name={this.props.email} type="mail" size="22px"/>
                                <IconLabel name={this.props.number} type="phone" size="22px"/>
                            </div>    
                        </Col>
                    </Row>
                </MediaQuery>
                <MediaQuery query="(max-width: 1500px)">
                    <Row type="flex" justify="space-around">
                        <Col>
                            <Row justify="space-between" type="flex" align="middle" style={{height: "100px"}}>
                                <Col>
                                    <img src={this.props.logo} style={{height: "48px"}}></img>
                                </Col>
                                <Col style={{lineHeight: "32px"}}>
                                    <IconLabel name={this.props.email} type="mail" size="16px"/>
                                    <IconLabel name={this.props.number} type="phone" size="16px"/>
                                </Col>
                            </Row>
                            <Row>
                                {MenuItems}
                            </Row>
                        </Col>
                    </Row>
                </MediaQuery>
            </React.Fragment>
        )
    }
}