import React from 'react';
import { Route, Switch } from 'react-router';
import './App.module.scss';
import Layout from './components/Layout';
import Home from './components/Home';
import ServiceSales from './components/ServiceSales';
import Galery from './components/Galery';
import Contact from './components/Contact';

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/predaj-servis' component={ServiceSales} />
            <Route path='/galeria' component={Galery} />
            <Route path='/kontakt' component={Contact} />
        </Switch>
    </Layout>
);
