import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import styles from './Galery.module.scss';
import ImageGallery from 'react-image-gallery';
import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import data from "../mock/gallery.json"
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import MediaQuery from "react-responsive";
import DocumentMeta from 'react-document-meta';
import metadata from "../mock/meta";

class Galery extends React.Component {

  constructor(props) {
    super(props);

    var imageList = [];
    data.images.map((element, index) => {
      imageList.push({
        src: require('../content/img/galery/' +  element.original),
        original: require('../content/img/galery/' +  element.original),
        thumbnail: require('../content/img/galery/' +  element.thumbnail),
        description: element.description,
        height: 1,
        width: 1
      })
    });

    this.state = {
      images: imageList,
      isImageOpen: false,
      selectedImageIndex: 0
    }
 }

 toggleModal = (e) => {
  var index = e._targetInst.index

  this.setState(state => ({ 
      isImageOpen: !state.isImageOpen, 
      selectedImageIndex: index
  }));
};
  render() {
      const { isImageOpen, selectedImageIndex } = this.state;

      const meta = {
        title: metadata.galery.title,
        description: metadata.galery.description,
        canonical: metadata.galery.canonical,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: metadata.galery.keywords
          }
        }
      };

      return ( 
        <DocumentMeta {...meta}>
          <React.Fragment>
            <MediaQuery maxDeviceWidth={799}>
              <Gallery photos={this.state.images} direction={"column"} onClick={this.toggleModal} />

              <ModalGateway>
                {isImageOpen ? (
                  <Modal onClose={this.toggleModal}>
                    <Carousel views={this.state.images} currentIndex={selectedImageIndex}/>
                  </Modal>
                ) : null}
              </ModalGateway>
            </MediaQuery>
          <MediaQuery minDeviceWidth={800}>
            <Row className={styles["galery-wrapper"]}>
                <ImageGallery items={this.state.images} autoPlay={true} slideInterval={7500} slideDuration={1500}/>
            </Row>
          </MediaQuery>
          </React.Fragment>
        </DocumentMeta>
      );
  }
}

export default connect()(Galery);