import React from 'react';
import { Icon, Row } from 'antd';

export default class IconLabel extends React.Component{
    render (){
        return(
            <Row justify="start" type="flex" align="middle" className={this.props.className}>
                <div style={{marginRight: "16px", fontSize: this.props.size }}>
                    <Icon type={this.props.type} />
                </div>
                <div style={{fontSize: this.props.size}}>
                    {this.props.name}
                </div>
            </Row>
        )
    }
}