import React, { Component } from "react";
import { connect } from 'react-redux';
import styles from './Contact.module.scss';
import { Row, Col, Card, Icon, Button } from "antd";
import IconLabel from "./common/IconLabel"
import MediaQuery from "react-responsive";
import data from "../mock/contact";
import map from '../content/img/map.png';
import DocumentMeta from 'react-document-meta';
import metadata from "../mock/meta";

class Contact extends Component {
  
    constructor(props) {
      super(props);

      this.state ={
          isMapVisible: false
      };

      this.onFloatClick = this.onFloatClick.bind(this);
    }

    onFloatClick() {
      this.setState({
          isMapVisible: !this.state.isMapVisible
      })
    }

    render() {
      const contactData = data.Contact;

      const meta = {
        title: metadata.contact.title,
        description: metadata.contact.description,
        canonical: metadata.contact.canonical,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: metadata.contact.keywords
          }
        }
      };

      return (
        <DocumentMeta {...meta}>
          <React.Fragment>
            {!this.state.isMapVisible && 
              <React.Fragment>
                <img src={map} className={styles["background-image"]} />
                <Row type="flex" justify="center" style={{height: "100%", maxHeight: "100%"}} align="middle">
                  <Row type="flex" justify="center" className={styles["contact"]}>
                    <MediaQuery query="(min-width: 801px) ">
                      <Col className={styles["contact-icon-wrapper"]}>
                          <Icon type="contacts"/>
                      </Col>
                    </MediaQuery>
                    <Col>
                      <Card title={contactData.Title} bordered={false} className={styles["contact-body"]} >
                        <Col>
                            <IconLabel name={contactData.Name} type="user" />
                            <IconLabel name={contactData.Email} type="mail" />
                            <IconLabel name={contactData.Phone} type="phone" />
                            <IconLabel name={[contactData.Street, <br/>, contactData.City]} type="environment" className={styles["address-row"]}/>
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                </Row>
                <Button type="primary" shape="circle" icon="environment" className={styles["contact-button"]} onClick={this.onFloatClick}/>
              </React.Fragment>
            }
            {this.state.isMapVisible && 
                <div className={styles["map-wrapper"]}>
                  <Button type="primary" shape="circle" icon="contacts" className={styles["map-button"]} onClick={this.onFloatClick} />
                  <iframe className={styles["map"]} src={contactData.Map} frameborder="0" scrolling="no"  marginheight="0" marginwidth="0"/>
                </div>
            }
          </React.Fragment>
        </DocumentMeta>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Contact);