import React from 'react';
import { connect } from 'react-redux';
import { Row, Menu, Select, Layout } from 'antd';
import MediaQuery from "react-responsive";
import styles from './ServiceSales.module.scss';
import data from "../mock/servicesales";
import DocumentMeta from 'react-document-meta';
import metadata from "../mock/meta";

const {Footer, Content, Sider, Header} = Layout

class ServiceSales extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            selected: 1,
            data: data.ServiceSales
        };

        this.onDropDownSelect = this.onDropDownSelect.bind(this);
        this.onMenuSelect = this.onMenuSelect.bind(this);
    }

    onDropDownSelect = (e) => {
        this.setState({
            selected: e
        })
    }

    onMenuSelect = (e) => {
      this.setState({
          selected: Number(e.key)
      })
    }

    renderOptions = (e) => {

        var container = this.state.data.find(c => c.id == this.state.selected);
        let options = container.content.map((element, index) => {
            return (
              <div className={styles["content-row"]} key={index}>
                    { element.company !== undefined &&
                        <div className={styles["header"]}>
                            <label>{element.company}</label>
                        </div>
                    }
                    { element.description !== undefined &&
                        <span>{element.description}</span>
                    }
              </div>
            );
          });
      
        return options;
    }

    renderDropDownOptions = (e) => {
        const { Option } = Select;
        let options = this.state.data.map((element, index) => {
            return (<Option value={element.id} key={element.id} className={styles["option"]}>{element.name}</Option>);
        });
        return options;
    }

    renderMenuOptions = (e) => {
        let options = this.state.data.map((element, index) => {
            return (
                <Menu.Item onClick={(e) => this.onMenuSelect(e)} key={element.id}>
                    <span>{element.name}</span>
                </Menu.Item>);
        }); 
        return options;
    }

    setBackgroundOptions = (e) => {
        var container = this.state.data.find(c => c.id == this.state.selected);
        return require('../content/img/servicesales/' + container.image)
    }

    render() {

        const meta = {
            title: metadata.saleservice.title,
            description: metadata.saleservice.description,
            canonical: metadata.saleservice.canonical,
            meta: {
              charset: 'utf-8',
              name: {
                keywords: metadata.saleservice.keywords
              }
            }
          };

        return (
            <DocumentMeta {...meta}>
                <Layout className={styles["servicesales"]}>
                    <Layout>
                        <MediaQuery query="(min-width: 801px)">
                            <Sider theme="light" style={{height: "100%", overflow: "auto"}} width="300">
                                <Menu defaultSelectedKeys={[String(this.state.selected)]}>
                                    {this.renderMenuOptions()}
                                </Menu>
                            </Sider>
                        </MediaQuery>
                        <MediaQuery query="(max-width: 800px)">
                            <Header>
                                <Select value={this.state.selected} onChange={(e) => this.onDropDownSelect(e)} size={'large'} className={styles["select-container"]}>
                                    {this.renderDropDownOptions()}
                                </Select>
                            </Header>
                        </MediaQuery>
                        <Content>
                            <img src={this.setBackgroundOptions()} className={styles["container-image"]} ></img>
                            <Row className={styles["container-text"]} >
                                    {this.renderOptions()}
                            </Row>
                        </Content>
                    </Layout>
                    <Footer>{data.Footer.text}</Footer>
                </Layout>
            </DocumentMeta>
        );
    }
}

export default connect(
)(ServiceSales);
