import React from 'react';
import styles from './BurgerMenu.module.scss';
import { Menu, Icon, Dropdown, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

export default class BurgerMenu extends React.Component{

    render(){

        const dropDownMenu = (
            <Menu
                className={styles["dropDownMenu"]}
                theme="light"
                mode="vertical"
                style={{top: "-4px"}}
                selectedKeys={this.props.selectedKeys}
                selectable
            >
                <Menu.Item key="/" >
                    <Link to="/">Domov</Link>
                </Menu.Item>
                <Menu.Item key="/predaj-servis">
                    <Link to="/predaj-servis">Predaj & Servis</Link>
                </Menu.Item>
                <Menu.Item key="/galeria">
                    <Link to="/galeria">Galéria</Link>
                </Menu.Item>
                <Menu.Item key="/kontakt" >
                    <Link to="/kontakt">Kontakt</Link>
                </Menu.Item>
            </Menu>
        );

        return(
            <Row justify="space-between" type="flex" align="middle" style={{margin: "0px 32px"}}>
                <Col>
                    <img src={this.props.logo} style={{height: "32px"}}></img>
                </Col>
                <Col>
                    <Dropdown trigger={['click']} overlay={dropDownMenu} placement="bottomRight" overlayStyle={{width: "100%" }} >
                        <Icon type="menu" className={styles["burgerIcon"]} />
                    </Dropdown>
                </Col>
            </Row>
        )
    }
}