import React from 'react';
import { Layout} from 'antd';
import styles from './Navbar.module.scss';
import logo from '../content/img/logo.png'
import { withRouter } from 'react-router-dom';
import MediaQuery from "react-responsive";
import BurgerMenu from "../components/common/BurgerMenu";
import NormalMenu from "../components/common/NormalMenu";

const { Header } = Layout;

class NavBar extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            selectedKeys: ["/"]
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            selectedKeys: [nextProps.location.pathname]
        });
    }

    componentWillMount(){
        this.setState({
            selectedKeys: [this.props.location.pathname]
        });
    }

    render() {
        return (
            <Header className={styles["navbar-wrapper"]}>
                <MediaQuery query="(min-width: 800px) and (min-height: 650px)">
                    <NormalMenu logo={logo} selectedKeys={this.state.selectedKeys} email="igorurban@gmail.com" number="+421 915 955 654" />
                </MediaQuery>
                <MediaQuery query="(max-width: 799px), (max-height: 649px)">
                    <BurgerMenu logo={logo} selectedKeys={this.state.selectedKeys} />
                </MediaQuery>
            </Header>           
        );
    }
}
export default withRouter(NavBar)