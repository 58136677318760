import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import styles from './Home.module.scss';
import MediaQuery from "react-responsive";
import data from "../mock/home";
import car from '../content/img/Loga.png'
import DocumentMeta from 'react-document-meta';
import metadata from "../mock/meta";

class Home extends React.Component {
  
    routeChange(path) {
        this.props.history.push(path);
    }

    render() {

        const meta = {
            title: metadata.home.title,
            description: metadata.home.description,
            canonical: metadata.home.canonical,
            meta: {
              charset: 'utf-8',
              name: {
                keywords: metadata.home.keywords
              }
            }
          };

        const Cards = (
            <div className={styles["card-container"]}>
                <Row className={styles["cardcontent"]}>
                    <Card bordered={true} title={data.home.SectionSalesService.Title} className={styles["materialcard"]} onClick={() => this.routeChange('predaj-servis')}>
                        <label>{data.home.SectionSalesService.Description}</label>            
                    </Card>
                </Row>
                <Row className={styles["cardcontent"]}>
                    <Card bordered={true} title={data.home.SectionGalery.Title} className={styles["materialcard"]} onClick={() => this.routeChange('galeria')}>
                        <label>{data.home.SectionGalery.Description}</label>             
                    </Card>
                </Row>
                <Row className={styles["cardcontent"]}>
                    <Card bordered={true} title={data.home.SectionContact.Title} className={styles["materialcard"]} onClick={() => this.routeChange('kontakt')}>                       
                        <label>{data.home.SectionContact.Description}</label>             
                    </Card>
                </Row>
            </div>
        )

        return (
            <DocumentMeta {...meta}>
                <div className={styles["homeWrapper"]}>
                    <MediaQuery query="(min-width: 1200px) and (min-height: 800px)">
                        <Row className={styles["large-content"]}>
                            <Col span={8} offset={1} style={{maxHeight: "100%"}}>
                                {Cards}
                            </Col>
                            <Col style={{maxHeight: "100%"}}>
                                <img src={car} className={styles["normal-image"]} />
                            </Col>
                        </Row>
                    </MediaQuery>
                    <MediaQuery query="(min-width: 1200px) and (max-height: 799px), (max-width: 1199px) and (min-width: 800px)">
                        <img src={car} className={styles["background-image"]} />
                        {Cards}
                    </MediaQuery>
                    <MediaQuery query="(max-width: 799px)">
                        {Cards}
                    </MediaQuery>
                </div>
            </DocumentMeta>
        );
    }
  }

  export default connect()(Home);

