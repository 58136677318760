import styles from './VerticalMenuItemDivider.module.scss';
import React from "react";

export default class VerticalItemMenuDivider extends React.Component{
    render(){
        return(
            <li className={styles["custom-menu-item"]}>
                <div className={styles["divider-wrapper"]}>
                    <span className={styles["divider"]} />
                </div> 
            </li>
        )
    }
}