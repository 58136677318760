import React from 'react';
import Navbar from './Navbar';
import { Layout } from 'antd';

const { Content } = Layout;

export default props => (
  <Layout>
    <Navbar />
    <Content>
      {props.children}
    </Content>
  </Layout>
);
